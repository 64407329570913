import { globalEnvironment } from './global';
import { COPILOT_COMMON_ENV } from './commons/copilot';
import { MODULES } from './module.config';
import { BASE_URL } from './commons/base-url';

export const environment = {
  ...globalEnvironment,
  ...BASE_URL,
  ...COPILOT_COMMON_ENV,
  admin_tool_url: 'https://gw-dev.cube.rina.org/admin-tool',
  routes: [MODULES['chat-section'].route,MODULES['file-section'].route],
  api: {
    ...globalEnvironment.api,
  },
  b2cPolicies: {
    tenant : 'rinagroupb2cdevl',
    clientId: '83405386-3e40-4526-b3f0-34f63eff8b3a',
    tenantId: 'c7788286-49f4-40db-bf26-fc1c677df787',
    redirectUri: 'https://marine-ai-dev.cube.rina.org/',
    //redirectUri: 'http://localhost:4200/', //For development
    redirectCourtesyPageUri: 'https://www.rina.org/',
    postLogoutRedirectUri: 'https://www.rina.org/',
    b2cLoginBaseUrl: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/',
    names: {
      resetPassword: 'B2C_1A_PASSWORDRESET',
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      resetPassword: {
        authority:
          'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
      editProfile: {
        authority:
          'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PROFILEEDIT',
      },
    },
    domain: 'https://test-login.rina.org/',
    subdomain: 'rinagroupb2cdevl.onmicrosoft.com',
    authorityDomain:
      'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com',
  },
  // auth: {
  //   ...globalEnvironment.auth,
  //   bearerExcludedUrls: [
  //     ...globalEnvironment.auth.bearerExcludedUrls,
  //     '//eni-test.cube.rina.org/notification',
  //   ],
  // },
  adminToolDomain: ['@rina.org'],
  localStorageKeyPrefix: 'cube-marine-copilot',
};
