import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Tenant,
  ProfileFeature,
  ProfileRole,
} from '../../app/models/auth/admin-tool.type';
import { environment } from '../../environments/environment';
import { CubeAuth } from '../module_b2c_login/CubeAuth.js'
import { selectTokenDetails } from '../store/selectors/auth.selectors';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  token: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  profile: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  private tenantJsonUrl = 'assets/json/tenant.json';
  private roleJsonUrl = 'assets/json/role.json';
  private featureJsonUrl = 'assets/json/features.json';

  constructor(
    private readonly http: HttpClient,
    private store: Store
  ) {}

  tenants(uniqueId: string): Observable<Array<Tenant>> {
    return this.http.get<Array<Tenant>>(
      this.checkEmailDomain()
        ? this.tenantJsonUrl
        : `${environment.ADMIN_TOOL_URL}/users/${uniqueId}/solutions/${environment.solution}/tenants`
      );
  }

  roles(uniqueId: string, tenant: string): Observable<Array<ProfileRole>> {
    return this.http.get<Array<ProfileRole>>(
      this.checkEmailDomain()
      ? this.roleJsonUrl
      : `${environment.ADMIN_TOOL_URL}/tenants/${tenant}/users/${uniqueId}/platforms/CUBE/solutions/${environment.solution}/roles`
    );
  }

  features(role: string, tenant: string): Observable<Array<ProfileFeature>> {
    return this.http.get<Array<ProfileFeature>>(
      this.checkEmailDomain()
        ? this.featureJsonUrl
        : `${environment.ADMIN_TOOL_URL}/tenants/${tenant}/roles/${role}/platforms/CUBE/solutions/${environment.solution}/features`
    );
  }

  login() {
    /*     this.msalService.loginRedirect();
     */
    CubeAuth.enableADB2C(
      environment.b2cPolicies.tenant,
      environment.b2cPolicies.clientId,
      environment.b2cPolicies.names.signUpSignIn,
      environment.b2cPolicies.redirectUri,
      environment.b2cPolicies.b2cLoginBaseUrl,
    );
    CubeAuth.setLogutRedirectUri(environment.b2cPolicies.postLogoutRedirectUri);

    CubeAuth.onADB2CNewToken = (idToken: any, expires: any, profileInfo: any) => {
      this.token.next(idToken);
      this.profile.next(profileInfo.name);
    };

    CubeAuth.auth();
  }

  logout() {
    /*     this.msalService.logoutRedirect();
     */

    CubeAuth.logout();
    this.profile.next(undefined);
    this.token.next(undefined);
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
  }

  isValidToken(expiration: number | undefined): boolean {
    return new Date(new Date().getTime() - 1000 * 300).valueOf() < (expiration || 1) * 1000;
  }

  resourceNeedsToken(request_url: string): boolean {
    return environment.bypass_auth_token_resources.every((resource) => !request_url.includes(resource));
  }

  cloneRequest(req: HttpRequest<unknown>, idToken: string, append_token: boolean): HttpRequest<unknown> {
    if (append_token) {
      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return clonedReq;
    } else {
      return req;
    }
  }

  public getCurrentTenant() {
    return localStorage.getItem('cube-rina-copilot_tenant');
  }

  checkEmailDomain(): boolean {
    let email: string = '';
    this.store.select(selectTokenDetails).subscribe((tokenDetails) => {
      email = tokenDetails.idTokenClaims.email;
    });

    return email
      ? environment.adminToolDomain.some((domain) => email.endsWith(domain))
      : false;
  }
}
